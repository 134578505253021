import * as React from 'react';

import Layout from '../../layout';
import Btn from '../../inc/btn';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import SaxMax from '../../../images/saxmax_steps.png';
import SaxImage from '../../../images/sax_small.png';
import MusicianImage from '../../../images/musician.png';

import BgImageOvalBorder from '../../../images/oval_border.svg';
import videoPlaceholder from '../../../images/footage_img.png';

import SectionBg from '../../../images/__new/mainpage/img4.jpg';
import SaxMaxImage from '../../../images/__new/mainpage/saxmax_noshd.png';
import Saxophone from '../../../images/__new/mainpage/sax.png';

const videoRectangleSection = () => {
  return (
    <section className='rectangleSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section bacground'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='bgImage'>
            <img
              className='pulseBase image1'
              src={BgImageOvalBorder}
              role='presentation'
              alt='oval'
            />
          </div>

          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              Hear how your instrument can sound with{' '}
              <span className='titleTextUnder'>SaxMax.</span>
            </h2>
          </div>
          <div className='videoWrapper'>
            <div className='videoContainer'>
              <img
                src={videoPlaceholder}
                alt='Video placeholder'
                loading='lazy'
              />
            </div>
          </div>
          <div className='textWrapper'>
            <div className='sectionTitle'>
              <p className='sectionTitleText'>
                <span className='titleTextUnder'>
                  Hey Joe, what's goin' on?{' '}
                </span>
                You can't get no satisfaction?
              </p>
            </div>
            <div className='textContainer'>
              <p className='textYellowBig'>
                You try and you try and you try? Don’t paint it so black.
                Imagine there's no limit. What a wonderful world this could be?
                It’s easy if you try. With a little help from a friend of
                course. We can work it out. Vienna calling. Come as you are. A
                change is gonna come. Let’s break on through to the other side.
              </p>
              <p className='textYellowBig small'>
                Play your sax in the city. Under the board walk, on the dock of
                the bay or over the rainbow . Hey Babe – take a walk on the wild
                side if you want. You can’t always get what you want? Maybe, but
                now you can play what you want. Everything is possible when you
                can play the unplayable. The best is yet to come. What'd I say?
                We can work it out. It’s easy if you try. You don’t have to be
                the sultan of swing to play a Bohemian Rhapsody. Start me up and
                I swear with every breathe you take – Hallelujah – you’ll feel
                the good vibrations. Yeah, now you really got me.
              </p>
            </div>
            <div className='sectionTitle imageTitle'>
              <div className='bgImage'>
                <img
                  className='image1'
                  src={SaxMaxImage}
                  role='presentation'
                  alt='SaxMax'
                  loading='lazy'
                />
              </div>
              <p className='sectionTitleText'>
                <span className='titleTextUnder'>
                  Introducing the new SaxMax:
                </span>
                To maximize your sax performance.
              </p>
            </div>
            <div>
              <Btn whiteImage>Buy the SaxMax</Btn>
            </div>
          </div>
        </Layout>
      </div>
      <div className='secondSection'>
        <div className='bgRectangle'></div>
        <Layout>
          <div className='bgImage'>
            <img
              className='pulseBase image1'
              src={BgImageOvalBorder}
              role='presentation'
              alt='oval'
            />
          </div>
          <div className='sectionTitle'>
            <img
              src={SaxMaxImage}
              alt={'SaxMax'}
              className='saxMaxImage'
              loading='lazy'
            />
            <div className='textContainer'>
              <p className='sectionTitleText'>
                <span className='titleTextUnder'>
                  Sometimes a little
                  <br /> piece can solve a<br /> big problem.
                </span>
              </p>
              <p className='sectionText'>
                <span className='yellowText'>Introducing the new SaxMax:</span>{' '}
                Minimizes your exercise frustration, maximizes your sax
                performance.
              </p>
            </div>
          </div>
        </Layout>
      </div>
      <div className='thirdSection'>
        <div className='bgRectangle'></div>
        <Layout>
          <div className='bgImage'>
            <img
              className='pulseBase image1'
              src={BgImageOvalBorder}
              role='presentation'
              alt='oval'
            />
          </div>
          <div className='sectionTitle'>
            <img
              src={Saxophone}
              alt={'Saxophone'}
              className='saxophoneImage'
              loading='lazy'
            />
            <p className={'textIcon'}>+</p>
            <img
              src={SaxMaxImage}
              alt={'SaxMax'}
              className='saxMaxImage'
              loading='lazy'
            />
            <p className={'textIcon equal'}>=</p>

            <p className='sectionTitleText'>
              <span className='titleTextUnder'>Max </span>
              <span className='titleTextUnder'>Your </span>
              <span className='titleTextUnder'>Sax</span>
            </p>
          </div>
        </Layout>
      </div>
      <div className='fourthSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section bacground'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='leftRightSection'>
            <div className='leftSection'>
              <div className='sectionTitle'>
                <h2 className='sectionTitleText'>
                  How to play the unplayable <br /> on your sax?{' '}
                  <span className='yellowText'>Easy.</span>
                </h2>
              </div>
              <HorizontalSectionLine />
              <div className='sectionDescription'>
                <p className='sectionDescriptionText'>
                  With the SaxMax you can not only play freely without
                  restrictions, you can also play pieces on the saxophone that
                  are considered unplayable. This is how it works!
                </p>
              </div>
              <Btn whiteImage>Buy the SaxMax</Btn>
            </div>
            <div className='rightSection'>
              <div className='boxContainer' data-aos='fade-up'>
                <div className='boxNumber'>
                  <p className='boxNumberText'>1.</p>
                </div>
                <div className='boxImage'>
                  <img
                    src={SaxMax}
                    alt='SaxMax'
                    className='saxImage'
                    loading='lazy'
                  />
                </div>
                <div className='boxText'>
                  <p className='text'>
                    Order your SaxMax
                    <br />
                    in our&nbsp;online shop
                  </p>
                </div>
              </div>
              <div className='boxContainer' data-aos='fade-up'>
                <div className='boxNumber'>
                  <p className='boxNumberText'>2.</p>
                </div>
                <div className='boxImage'>
                  <img
                    src={SaxImage}
                    alt='Saxophone'
                    className='saxophoneImage'
                    loading='lazy'
                  />
                </div>
                <div className='boxText'>
                  <p className='text'>
                    Attach it to your saxophone
                    <br />
                    in just one&nbsp;minute!
                  </p>
                </div>
              </div>
              <div className='boxContainer' data-aos='fade-up'>
                <div className='boxNumber'>
                  <p className='boxNumberText'>3.</p>
                </div>
                <div className='boxImage'>
                  <img
                    src={MusicianImage}
                    alt='Musician'
                    className='musicianImage'
                    loading='lazy'
                  />
                </div>
                <div className='boxText'>
                  <p className='text'>
                    Get everything outf&nbsp;of&nbsp;your <br />
                    sax and play the&nbsp;unplayable!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default videoRectangleSection;
