import * as React from 'react';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Layout from '../../layout';
import Btn from '../../inc/btn';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';
import BgImageStar from '../../../images/star.svg';

import Patented from '../../../images/patented.png';
import MaxSaxLogo from '../../../images/__new/mainpage/saxmax-logo.png';

import ViewerScene from '../../tool3d';

const MaxYourSaxSection = () => {
  return (
    <section className='maxYourSaxSection'>
      <Layout>
        <div className='leftRightSection'>
          <div className='leftSection'>
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                We’ve made <span className='yellowText'>SaxMax</span> to
                max&nbsp;your&nbsp;sax.
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='sectionDescriptionText'>
                But that is not all! Whether for professionals, beginners, music
                students, or instructors. Whether it’s jazz, pop, hip hop,
                classical, country, indie, rock or whatever it is that you play
                — <strong>SaxMax extracts the max out of your sax!</strong>
              </p>
            </div>
            <Btn>Buy the SaxMax</Btn>
          </div>
          <div className='rightSection'>
            <div className='bgImage'>
              <img
                className='floatBase image1'
                src={BgImageOval}
                role='presentation'
                alt='oval'
              />
              <img
                className='floatBase image2'
                src={BgImageOval}
                role='presentation'
                alt='oval'
              />
              <img
                className='pulseBase image3'
                src={BgImageOvalBorder}
                role='presentation'
                alt='oval'
              />
              <img
                className='pulseBase image4'
                src={BgImageOvalBorder}
                role='presentation'
                alt='oval'
              />
              <img
                className='rotateBase image5'
                src={BgImageStar}
                role='presentation'
                alt='star'
              />
              <img
                className='rotateBase image6'
                src={BgImageStar}
                role='presentation'
                alt='star'
              />
              <img
                className='rotateBase image7'
                src={BgImageStar}
                role='presentation'
                alt='star'
              />
              <img
                className='rotateBase image8'
                src={BgImageStar}
                role='presentation'
                alt='star'
              />
              <div className='saxImageContainer rotatedContainer'>
                <ViewerScene
                  width={500}
                  height={500}
                  metalColor={'gold'}
                  feltColor={'red'}
                  isPreview={true}
                />
                <p className='text' data-aos='fade-up'>
                  Made <br /> in Vienna
                </p>
              </div>
              <img
                src={Patented}
                alt='Patented'
                className='patentedImage'
                loading='lazy'
              />
              <img
                src={MaxSaxLogo}
                alt='MaxSax logo'
                className='maxSaxLogo'
                loading='lazy'
              />
            </div>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default MaxYourSaxSection;
