import * as React from 'react';

import Layout from '../../layout';

import SectionBg1 from '../../../images/__new/mainpage/img6.jpg';
import SectionBg2 from '../../../images/__new/mainpage/img7.jpg';

import Logo from '../../../images/__new/mainpage/logo-mark.png';

const FooterRectangleSection = () => {
  return (
    <section className='footerRectangleSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              <span className='yellowText'>
                PLAY WHAT <br />
                YOU FEEL.
              </span>
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
      <div className='secondSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg2}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='bgImage'>
            <img
              className='floatBase logo'
              src={Logo}
              role='presentation'
              alt='oval'
              loading='lazy'
            />
          </div>
          <div className='sectionTitle'>
            <p className='sectionTitleText'>FEEL THE FLOW.</p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default FooterRectangleSection;
