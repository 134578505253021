import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';

const SatisfactionSection = () => {
  return (
    <section className='satisfactionSection'>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image3'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionContentWrapper'>
          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              <span className='titleTextUnder'>
                The satisfaction guarantee.
              </span>
              No satisfaction? No&nbsp;Problem!
            </h2>
          </div>
          <HorizontalSectionLine />
          <div className='sectionDescription'>
            <p className='sectionDescriptionText'>
              We support musicians to exceed their musical expectations and to
              develop freely.
              <br />
              This includes absolute trust and a service with which you should
              be completely satisfied. That's why we want to make your decision
              as easy as possible for you - just like playing with
              your&nbsp;sax.
              <Link to='/read-more'>
                <span className='readMoreBtn'>Read&nbsp;more…</span>
              </Link>
            </p>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default SatisfactionSection;
