import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import BgImageOvalBorder from '../../../images/oval_border.svg';

import SectionBg from '../../../images/bg1.png';

const LimitSection = () => {
  return (
    <section className='limitSection'>
      <div className='bgRectangle'>
        <img
          src={SectionBg}
          alt='Section bacground'
          className='sectionBg'
          loading='lazy'
        />
      </div>
      <Layout>
        <div className='bgImage'>
          <img
            className='pulseBase image1'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image2'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            Your only limit is your head.
            <span className='titleTextUnder'>Not your fingers.</span>
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <div className='leftSectionDescription'>
            <p className='sectionDescriptionText'>
              Good news! You are not alone in this. Your struggle with your
              instrument and your doubts are real. Coltrane has experienced it.
              Charlie Parker, Lisa Simpson, Jimi Hendrix and even Beethoven.
              Every musician at some point reached the moment where he could not
              express his full talent because his/her instrument itself is
              obstructing the way.
              <br />
              <br />
              They all faced this challenge. They all failed to make progress.
              They all wanted to break through this impenetrable musical wall in
              order to be completely free.
            </p>
          </div>
          <div className='rightSectionDescription'>
            <p className='sectionDescriptionText'>
              This is a well-known and widespread problem. It’s just that no one
              has wanted or been able to solve it so far. There is a solution
              however to every problem out there. The SaxMax is the solution to
              your problem and it’s super easy to put into practice. Music
              experimenters, music students, hobby musicians, music teachers or
              professional musicians—we want to give inspiration and tools to
              musicians for overcoming the boundaries.
              <br />
              <br />
              Music&nbsp;has&nbsp;no&nbsp;boundaries. Never.&nbsp;Nowhere.
              <Link to='/read-more'>
                <span className='readMoreBtn'>Read&nbsp;more…</span>
              </Link>
            </p>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default LimitSection;
