import React, { useEffect } from 'react';

import '../scss/main.scss';

import Seo from '../components/inc/seo';

import Header from '../components/header';
import HeaderContent from '../components/homepage/headerContent';
import PotentialSection from '../components/homepage/potentialSection';
import EasyWayToPlaySection from '../components/homepage/easyWayToPlaySection';
import LimitlessSection from '../components/homepage/limitlessSection';
import MaxYourSaxSection from '../components/homepage/maxYourSaxSection/maxYourSaxSection';
import ListSection from '../components/homepage/listSection';
import LimitSection from '../components/homepage/limitSection';
import VideoRectangleSection from '../components/homepage/videoRectangleSection';
import SatisfactionSection from '../components/homepage/satisfactionSection';
import TestimonialSection from '../components/homepage/testimonialSection';
import FunWayToPlaySection from '../components/homepage/funWayToPlaySection';
import Footer from '../components/footer';
import EntertainSection from '../components/homepage/entertainSection/entertainSection';
import TopBtn from '../components/inc/topBtn';

import FooterRectangleSection from '../components/homepage/footerRectangleSection/';

const IndexPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 500,
      });
    }, 300);
  }, []);

  return (
    <>
      <Seo />
      <Header homepage>
        <HeaderContent />
      </Header>
      <main>
        <PotentialSection />
        <EasyWayToPlaySection />
        <LimitlessSection />
        <MaxYourSaxSection />
        <ListSection />
        <LimitSection />
        <VideoRectangleSection />
        <SatisfactionSection />
        <TestimonialSection />
        <FunWayToPlaySection />
        <EntertainSection homepage />
        <FooterRectangleSection />
      </main>
      <Footer homepage />
      <TopBtn />
    </>
  );
};

export default IndexPage;
