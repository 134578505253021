import * as React from 'react';
import { Link } from 'gatsby';

import BtnArrow from '../../../../src/images/btn_arrow.svg';
import HeroImg from '../../../../src/images/header_photo.png';

const HeaderContent = (props) => {
  return (
    <div className={'homeHeaderContent'}>
      <div className={'heroImage'}>
        <img src={HeroImg} alt={'Saxophonist'} loading='lazy' />
      </div>
      <h1 className='title'>
        <span className={'bigText'}>Max</span>
        <span className={'smallText'}>your</span>
        <span className={'bigText'}>Sax.</span> <br />
        With the SaxMax!
      </h1>
      <h2 className='underTitle'>
        We make creative tools that make creative&nbsp;musicians
      </h2>
      <div className='buttonsContainer'>
        <Link to='/customize-your-sax'>
          <div className='button'>
            <p className={'buttonText'}>Order SaxMax now</p>
            <img src={BtnArrow} alt='Button Arrow' />
          </div>
        </Link>
        <Link to='/'>
          <div className='buttonTransparent'>
            <p className={'buttonText'}>Download free pdf</p>
            <p className='buttonExtendText'>
              „10 easy ways to play the unplayable on your sax”
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HeaderContent;
