import * as React from 'react';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Layout from '../../layout';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';

import BlockImage1 from '../../../images/sax.png';
import BlockImage2 from '../../../images/hand.png';
import BlockImage3 from '../../../images/cloud.png';

const PotentialSection = () => {
  return (
    <section className='potentialSection'>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image3'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            Unlocking your <br /> full potential isn’t&nbsp;easy.
            <span className='titleTextUnder'>Unless you have a&nbsp;key.</span>
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <div className='leftSectionDescription'>
            <p className='sectionDescriptionText'>
              Every musician arrives at a point where he/she gets stuck and
              cannot go any further musically. Even for music students and
              professionals, playing and practicing is hardly always
              a&nbsp;pleasure. It is frustrating when you get to your musical
              limits and you cannot play as you would like to. This simply
              should not&nbsp;be.
            </p>
          </div>
          <div className='rightSectionDescription'>
            <p className='sectionDescriptionText'>
              What would be musically possible if you could exceed your own
              expectations, if you could overcome the boundaries? With{' '}
              <strong>SaxMax</strong>, we bring not only fun and a sense of
              achievement back into playing, we also help musicians to extract
              their full potential ⎯ both from themselves and from
              their&nbsp;saxophone.
            </p>
          </div>
        </div>
        <div className='potentialBlocks'>
          <div className='blockContainer' data-aos='fade-up'>
            <div className='blockImage'>
              <img
                src={BlockImage1}
                className='saxImage'
                alt='Sax'
                loading='lazy'
              />
            </div>
            <div className='blockTitle'>
              <h3 className='blockTitleText'>
                It’s not easy to&nbsp;play freely
              </h3>
            </div>
            <div className='blockText'>
              <p className='text'>
                The saxophone is not ergonomic. This restricts the playing style
                on this wonderful instrument. No musician can play his sax
                completely freely. A belt is of little use.
              </p>
            </div>
          </div>
          <div className='blockContainer' data-aos='fade-up'>
            <div className='blockImage'>
              <img
                src={BlockImage2}
                className='handImage'
                alt='Hand'
                loading='lazy'
              />
            </div>
            <div className='blockTitle'>
              <h3 className='blockTitleText'>Limited finger skill</h3>
            </div>
            <div className='blockText'>
              <p className='text'>
                When playing with a strap, you push your sax “thumb hook” with
                your right thumb. The force you have to use puts strain on our
                arm muscles and finger joints, which in turn limits your
                dexterity.
              </p>
            </div>
          </div>
          <div className='blockContainer' data-aos='fade-up'>
            <div className='blockImage'>
              <img
                src={BlockImage3}
                className='cloudImage'
                alt='Cloud'
                loading='lazy'
              />
            </div>
            <div className='blockTitle'>
              <h3 className='blockTitleText'>Blockades and frustration</h3>
            </div>
            <div className='blockText'>
              <p className='text'>
                As a musician, you want to refine your playing technique. As a
                music student you want to finally be able to play your favorite
                songs perfectly, but at some point you get stuck. It's
                frustrating.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default PotentialSection;
