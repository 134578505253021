import * as React from 'react';
import Slider from 'react-slick';

import itemImage1 from '../../../../images/avatar1.png';
import itemImage2 from '../../../../images/avatar2.png';
import itemImage3 from '../../../../images/avatar3.png';

const Carousel = () => {
  const settings = {
    className: 'slickContainer',
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    touchThreshold: 10,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          centerMode: true,
          variableWidth: true,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 400,
        settings: {
          centerMode: true,
          variableWidth: false,
          centerPadding: '15px',
        },
      },
      {
        breakpoint: 350,
        settings: {
          centerMode: true,
          variableWidth: false,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 340,
        settings: {
          centerMode: true,
          variableWidth: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <div className='itemWrapper'>
          <div className='itemContainer'>
            <div className='itemImageWrapper'>
              <img
                src={itemImage1}
                alt='ItemHeaderImage'
                className='itemImage'
              />
            </div>
            <div className='itemTextConrainer'>
              <p className='itemText'>
                „The saxophone is not ergonomic. This restricts the playing
                style on this wonderful instrument. No musician can play his sax
                completely freely. A belt is of little use.”
              </p>
              <div className='itemUnderTextWrapper'>
                <p className='itemTextName'>- Donald Ryan,</p>
                <p className='itemTextWorkplace'>
                  Vienna Philharmonic Orchestra
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='itemWrapper'>
          <div className='itemContainer'>
            <div className='itemImageWrapper'>
              <img
                src={itemImage2}
                alt='ItemHeaderImage'
                className='itemImage'
              />
            </div>
            <div className='itemTextConrainer'>
              <p className='itemText'>
                „When playing with a strap, you push your sax “thumb hook” with
                your right thumb. The force you have to use puts strain on our
                arm muscles and finger joints, which in turn limits your
                dexterity.”
              </p>
              <div className='itemUnderTextWrapper'>
                <p className='itemTextName'>- Kelly Hughes,</p>
                <p className='itemTextWorkplace'>The Hallé</p>
              </div>
            </div>
          </div>
        </div>
        <div className='itemWrapper'>
          <div className='itemContainer'>
            <div className='itemImageWrapper'>
              <img
                src={itemImage3}
                alt='ItemHeaderImage'
                className='itemImage'
              />
            </div>
            <div className='itemTextConrainer'>
              <p className='itemText'>
                „As a musician, you want to refine your playing technique. As a
                music student you want to finally be able to play your favorite
                songs perfectly, but at some point you get stuck. It's
                frustrating.”
              </p>
              <div className='itemUnderTextWrapper'>
                <p className='itemTextName'>- Susan Carter,</p>
                <p className='itemTextWorkplace'>London Symphony Orchestra</p>
              </div>
            </div>
          </div>
        </div>
        <div className='itemWrapper'>
          <div className='itemContainer'>
            <div className='itemImageWrapper'>
              <img
                src={itemImage1}
                alt='ItemHeaderImage'
                className='itemImage'
              />
            </div>
            <div className='itemTextConrainer'>
              <p className='itemText'>
                „The saxophone is not ergonomic. This restricts the playing
                style on this wonderful instrument. No musician can play his sax
                completely freely. A belt is of little use.”
              </p>
              <div className='itemUnderTextWrapper'>
                <p className='itemTextName'>- Donald Ryan,</p>
                <p className='itemTextWorkplace'>
                  Vienna Philharmonic Orchestra
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='itemWrapper'>
          <div className='itemContainer'>
            <div className='itemImageWrapper'>
              <img
                src={itemImage2}
                alt='ItemHeaderImage'
                className='itemImage'
              />
            </div>
            <div className='itemTextConrainer'>
              <p className='itemText'>
                „When playing with a strap, you push your sax “thumb hook” with
                your right thumb. The force you have to use puts strain on our
                arm muscles and finger joints, which in turn limits your
                dexterity.”
              </p>
              <div className='itemUnderTextWrapper'>
                <p className='itemTextName'>- Kelly Hughes,</p>
                <p className='itemTextWorkplace'>The Hallé</p>
              </div>
            </div>
          </div>
        </div>
        <div className='itemWrapper'>
          <div className='itemContainer'>
            <div className='itemImageWrapper'>
              <img
                src={itemImage3}
                alt='ItemHeaderImage'
                className='itemImage'
              />
            </div>
            <div className='itemTextConrainer'>
              <p className='itemText'>
                „As a musician, you want to refine your playing technique. As a
                music student you want to finally be able to play your favorite
                songs perfectly, but at some point you get stuck. It's
                frustrating.”
              </p>
              <div className='itemUnderTextWrapper'>
                <p className='itemTextName'>- Susan Carter,</p>
                <p className='itemTextWorkplace'>London Symphony Orchestra</p>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
