import * as React from 'react';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Carousel from './carousel';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';
import BgImageQuotes from '../../../images/quotes.svg';

const TestimonialSection = () => {
  return (
    <section className='testimonialSection'>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image2'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
          <img
            className='image3'
            src={BgImageQuotes}
            role='presentation'
            alt='quotes'
            loading='lazy'
          />
        </div>
        <div className='testimonial'>
          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              <span className='titleTextUnder'>Testimonials.</span>
              See what professionals have to say about using SaxMax.
            </h2>
          </div>
          <HorizontalSectionLine />
          <div className='sectionDescription'>
            <div className='leftSectionDescription'>
              <p className='sectionDescriptionText'>
                We’ve asked the SaxMax users to share their expierience and
                write a few words about the product. Take a look at their
                testimonials.
              </p>
            </div>
            <div className='rightSectionDescription'>
              <p className='sectionDescriptionText'>
                Most of them are professional musicians and saxophone players
                with many years of experience playing sax.
              </p>
            </div>
          </div>
        </div>
      </Layout>
      <div className='testimonialCarouselContainer'>
        <Carousel />
      </div>
    </section>
  );
};

export default TestimonialSection;
