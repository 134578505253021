import * as React from 'react';
import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import BgImageStar from '../../../images/star.svg';
import BgSaxophonist1 from '../../../images/oval_photo1.png';
import BgSaxophonist2 from '../../../images/oval_photo_2.png';

const FunWayToPlaySection = () => {
  return (
    <section className='funWayToPlaySection'>
      <Layout>
        <div className='bgImage'>
          <img
            className='rotateBase image1'
            src={BgImageStar}
            role='presentation'
            alt='oval'
          />
          <img
            className='rotateBase image2'
            src={BgImageStar}
            role='presentation'
            alt='oval'
          />
          <img
            className='rotateBase image3'
            src={BgImageStar}
            role='presentation'
            alt='oval'
          />
          <div className='saxophonistContainer1' data-aos='fade-up'>
            <img
              className='saxophonist1'
              alt='Saxophonist'
              src={BgSaxophonist1}
              loading='lazy'
            />
          </div>
          <div className='saxophonistContainer2' data-aos='fade-up'>
            <img
              className='saxophonist2'
              alt='Saxophonist'
              src={BgSaxophonist2}
              loading='lazy'
            />
          </div>
        </div>
        <div className='sectionContentWrapper'>
          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              <span className='titleTextUnder'>
                We believe in the easy way&nbsp;to&nbsp;play.
              </span>
              And that’s the fun way&nbsp;to&nbsp;play.
            </h2>
          </div>
          <HorizontalSectionLine />
          <div className='sectionDescription'>
            <p className='sectionDescriptionText'>
              When musicians play with a flow and freedom, they take their
              talent to the next level. So they will be able to play
              the&nbsp;unplayable and become extraordinary musicians.
            </p>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default FunWayToPlaySection;
