import * as React from 'react';

import Layout from '../../layout';

import BgImageOvalBorder from '../../../images/oval_border.svg';

import SectionBg from '../../../images/__new/mainpage/img1.jpg';

const LimitSection = () => {
  return (
    <section className='limitlessSection'>
      <div className='bgRectangle'>
        <img
          src={SectionBg}
          alt='Section bacground'
          className='sectionBg'
          loading='lazy'
        />
      </div>
      <Layout>
        <div className='bgImage'>
          <img
            className='pulseBase image1'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image2'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            <span className='titleTextUnder'>
              I AM <br /> LIMITLESS.
            </span>
          </h2>
          <div className='bgTextContainer'>
            <p className='bgText'>RISE ABOVE</p>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default LimitSection;
