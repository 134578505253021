import * as React from 'react';

import parse from 'html-react-parser';

import textListJson from './listContent.json';

import Layout from '../../layout';

import ListItem from './listItem';

import BgImageOval from '../../../images/oval.svg';

import SaxophonistImage from '../../../images/__new/mainpage/img2.jpg';

import Logo1 from '../../../images/__new/mainpage/logos_ready/1b.png';
import Logo2 from '../../../images/__new/mainpage/logos_ready/2b.png';
import Logo3 from '../../../images/__new/mainpage/logos_ready/3b.png';
import Logo4 from '../../../images/__new/mainpage/logos_ready/4b.png';
import Logo5 from '../../../images/__new/mainpage/logos_ready/5b.png';
import Logo6 from '../../../images/__new/mainpage/logos_ready/6b.png';
import Logo7 from '../../../images/__new/mainpage/logos_ready/7b.png';
import Logo8 from '../../../images/__new/mainpage/logos_ready/8b.png';
import Logo9 from '../../../images/__new/mainpage/logos_ready/9b.png';
import Logo10 from '../../../images/__new/mainpage/logos_ready/10b.png';
import Logo11 from '../../../images/__new/mainpage/logos_ready/11b.png';
import Logo12 from '../../../images/__new/mainpage/logos_ready/12b.png';

const ListSection = () => {
  return (
    <section className='listSection'>
      <div className='bgRectangle' />
      <Layout>
        <div className='bgImage'>
          <img
            loading='lazy'
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='listWrapper'>
          {textListJson.listText.map((text, index) => (
            <ListItem key={index} aos='fade-up' aosDelay={`${index * 100}`}>
              {parse(text)}
            </ListItem>
          ))}
        </div>
        <div className='imageSection'>
          <div className='left'>
            <p className='sectionTitleText'>
              PUSH YOUR <br />
              BOUNDARIES.
              <span className='yellowText'>
                PLAY THE <br />
                UNPLAYABLE.
              </span>
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
          <div className='right'>
            <div className='imageContainer'>
              <img loading='lazy' src={SaxophonistImage} alt='Saxophonist' />
            </div>
          </div>
        </div>
        <div className='clientSection'>
          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              <span className='yellowText'>SaxMax is trusted</span> with the
              leading institutions.
            </h2>
          </div>
          <div className='logoSection '>
            <div className='logoWrapper' data-aos='fade-up'>
              <img
                loading='lazy'
                src={Logo1}
                alt='Vienna Business Agency logo'
              />
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='100'
            >
              <img
                loading='lazy'
                src={Logo2}
                alt='Austria Wirtschafts Service logo'
              />
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <img
                loading='lazy'
                src={Logo3}
                alt='FFG logo'
                className='connselmerLogo'
              />
            </div>

            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='300'
            >
              <img
                loading='lazy'
                src={Logo4}
                alt='Federal ministry Digital and Economic Affairs logo'
              />
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='400'
            >
              <a
                target='_blank'
                rel='noreferrer'
                href='https://uspto.report/patent/grant/10,573,283'
              >
                <img
                  loading='lazy'
                  src={Logo5}
                  alt='Departament of Commerce logo'
                />
              </a>
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='500'
            >
              <img
                loading='lazy'
                src={Logo6}
                alt='European Patent Office Logo'
              />
            </div>

            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='600'
            >
              <img
                loading='lazy'
                src={Logo7}
                alt='Canadian Intellectual Panent Office Logo'
              />
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='700'
            >
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.ipo.gov.uk/p-ipsum/Case/PublicationNumber/GB2570361'
              >
                <img
                  loading='lazy'
                  src={Logo8}
                  alt='Intellectual Property Office'
                />
              </a>
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='800'
            >
              <img
                loading='lazy'
                src={Logo9}
                alt='German Panent and Trade mark Office'
              />
            </div>

            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='900'
            >
              <img
                loading='lazy'
                src={Logo10}
                alt='University of music and performing arts vienna Logo'
              />
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='1000'
            >
              <img
                loading='lazy'
                src={Logo11}
                alt='Intellectual property office Logo'
              />
            </div>
            <div
              className='logoWrapper'
              data-aos='fade-up'
              data-aos-delay='1100'
            >
              <img
                loading='lazy'
                src={Logo12}
                alt='German patent and trademark office logo'
              />
            </div>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default ListSection;
