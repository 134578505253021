import * as React from 'react';

import tickImage from '../../../../images/tick.svg';

const ListItem = ({ children, aos, aosDelay }) => {
  return (
    <div className='listItem' data-aos={aos} data-aos-delay={aosDelay}>
      <div className='tickContainer'>
        <img src={tickImage} alt='tick' />
      </div>
      <div className='textContainer'>
        <p className='text'>{children}</p>
      </div>
    </div>
  );
};

export default ListItem;
