import * as React from 'react';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Layout from '../../layout';
import Btn from '../../inc/btn';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';
import BgImageStar from '../../../images/star.svg';
import BgImageWhiteStar from '../../../images/whiteStar.svg';
import BgImageSaxMax from '../../../images/saxmax_silver.png';

import Patented from '../../../images/patented.png';
import MaxSaxLogo from '../../../images/__new/mainpage/saxmax-logo.png';
const EntertainSection = () => {
  return (
    <>
      <section className={'entertainSection homepage'}>
        <div className='bgRectangle' />
        <Layout>
          <div className='leftRightSection'>
            <div className='leftSection'>
              <div className='sectionTitle'>
                <h2 className='sectionTitleText'>
                  Here you are now. Entertain us.
                  <br />
                  <span className='blackText'>Now - it’s your turn.</span>
                </h2>
              </div>
              <HorizontalSectionLine />
              <div className='sectionDescription'>
                <p className='sectionDescriptionText'>
                  Maximize your sax performance with the SaxMax and play more
                  freely, better, faster —and more creatively. Now it’s your
                  turn.
                  <strong>Rise above: Max your Sax. With SaxMax.</strong>
                </p>
              </div>
              <Btn>Buy the SaxMax</Btn>
            </div>
            <div className='rightSection'>
              <div className='bgImage'>
                <img
                  className='floatBase image1'
                  src={BgImageOval}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='floatBase image2'
                  src={BgImageOval}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image3'
                  src={BgImageOvalBorder}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image4'
                  src={BgImageOvalBorder}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image5'
                  src={BgImageOvalBorder}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image6'
                  src={BgImageOvalBorder}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='rotateBase image7'
                  src={BgImageStar}
                  role='presentation'
                  alt='star'
                />
                <img
                  className='rotateBase image8'
                  src={BgImageWhiteStar}
                  role='presentation'
                  alt='star'
                />
                <img
                  className='rotateBase image9'
                  src={BgImageWhiteStar}
                  role='presentation'
                  alt='star'
                />
                <img
                  className='rotateBase image10'
                  src={BgImageStar}
                  role='presentation'
                  alt='star'
                />
                <img
                  className='rotateBase image11'
                  src={BgImageStar}
                  role='presentation'
                  alt='star'
                />
                <img
                  className='rotateBase image12'
                  src={BgImageStar}
                  role='presentation'
                  alt='star'
                />
                <div className='saxImageContainer'>
                  <img
                    className='saxImage'
                    src={BgImageSaxMax}
                    alt='MaxSax'
                    data-aos='fade-up'
                    loading='lazy'
                  />

                  <p className='text' data-aos='fade-up'>
                    Made <br /> in Vienna
                  </p>
                </div>
                <img src={Patented} alt='Patented' className='patentedImage' />
                <img
                  src={MaxSaxLogo}
                  alt='MaxSax logo'
                  className='maxSaxLogo'
                />
              </div>
            </div>
          </div>
        </Layout>
      </section>
    </>
  );
};

export default EntertainSection;
