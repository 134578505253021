import * as React from 'react';
import { Link } from 'gatsby';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Layout from '../../layout';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';

const EasyWayToPlaySection = () => {
  return (
    <section className='easyWayToPlaySection'>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image3'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            We believe in the easy&nbsp;way to&nbsp;play.
            <span className='titleTextUnder'>
              And that’s the fun&nbsp;way to&nbsp;play.
            </span>
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <p className='sectionDescriptionText'>
            We at <strong>GRADIVARI</strong>, know that most musicians aspire to
            become the creative artists that they have inside themselves. To
            that end, they have to play freely. To push the boundaries and to
            unlock their full potential. The problem is that they often arrive
            at a point where they get stuck and cannot go&nbsp;further.
            <Link to='/read-more'>
              <span className='readMoreBtn'>Read&nbsp;more…</span>
            </Link>
          </p>
        </div>
      </Layout>
    </section>
  );
};

export default EasyWayToPlaySection;
